<template lang="pug">
FcwModal.FcwSuspendedUserModal(
  :width="464"
  :title="t('profile__suspended_user_modal__title')"
  persistent
)
  .FcwSuspendedUserModal__title {{ t('profile__suspended_user_modal__text') }}

  template(#actions="{ close }")
    FButton(@click="close")
      span {{ t('common__close') }}
</template>

<style lang="stylus">
.FcwSuspendedUserModal
  &__title
    white-space pre-line
</style>

<script setup lang="ts">
const { t } = useI18n();
</script>

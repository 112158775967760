<template lang="pug">
.profile
  template(v-if="!isSmAndDown")
    FcwProfileNav.profile__header(
      :user-name="user?.firstname"
      :home-route="homepage"
      :links="navbarLinks"
      @logout="logout"
    )
    .profile__sidebar
      .profile__sidebarStart
        FcwSidebar(:links="sidebarLinks")
      .profile__sidebarEnd
        FcwProfileFooter(
          :customer-service-title="t('footer__customer_service__title')"
          :customer-service-links="customerServiceLinks"
          :legal-links="legalLinks"
          :editor-text="t('footer__powered_by')"
        )
    .profile__content(:class="contentClasses")
      .profile__contentTitle
        Transition(name="profile__backLink__transition")
          .profile__backLink(v-if="route.meta.back")
            FcwButtonLink(
              icon
              ghost
              background="transparent"
              size="small"
              :location="{ name: String(route.meta.back) }"
            )
              FIcon.profile__backLinkIcon(
                name="chevronDown"
                :stroke-width="2"
                stroke-color="neutral--dark-4"
              )
        FcwGeolocationModal(v-if="userNeverOptedGeolocation")
        FcwSuspendedUserModal(v-if="isSuspendedUser")
        Transition(
          name="profile__contentTitle__transition"
          mode="out-in"
        )
          .profile__title(:key="String(route.meta.title)")
            h3 {{ pageTitle }}
      .profile__contentWrapper(ref="contentWrapperRef")
        NuxtPage(
          :transition="{ name: 'slide-left', mode: 'out-in', duration: 200 }"
        )
  Teleport(to="body")
    FcwAppStoresFallback.profile__mobileFallback(v-if="isSmAndDown")
</template>

<style lang="stylus">
.profile
  display flex
  width 100vw
  height 100vh
  background var(--color--neutral--light-4)

.profile__header
  position fixed
  z-index 5
  width 'calc(100% - %s)' % rem(32)
  left rem(32)

.profile__sidebar
  display flex
  flex-direction column
  justify-content space-between
  position relative
  padding rem(160) rem(40) rem(24) rem(0)
  color var(--color--primary--light-2)
  background var(--color--neutral--dark-5)
  flex 0 0 rem(280)
  height 100%
  elevation-light(3)

.profile__sidebarStart
  height 100%
  overflow auto

.profile__sidebarEnd
  padding-top rem(16)
  padding-left rem(40)

.profile__content
  display flex
  flex-direction column
  position relative
  width 100%
  height 100%
  color var(--color--neutral--dark-4)
  overflow hidden
  padding rem(136) rem(64) rem(56)

  &::before
    content ''
    position absolute
    left 0
    transform translateX(-100%)
    transition transform 250ms
    top rem(152)
    width rem(48)
    border-top-right-radius rem(8)
    border-bottom-right-radius rem(8)
    height rem(4)
    background var(--color--neutral--dark-4)

  &--rootLink
    transition-delay 400ms

  &--rootLink
    &::before
      transform translateX(0)

.profile__contentTitle
  display flex
  align-items center
  margin-bottom rem(32)
  margin-left rem(8)
  transition margin-bottom 200ms
  transform translateY(0)

.profile__backLink
  position absolute
  left rem(-48)
  top rem(2)
  margin-right rem(16)

.profile__backLinkIcon
  transform rotate(90deg)
  margin-left rem(-4)

.profile__contentWrapper
  height 100%
  overflow auto
  padding rem(8)

.profile__backLink__transition
  &-leave-active
    transition transform 180ms, opacity 180ms

  &-enter-active
    transition transform 250ms, opacity 250ms
    transition-delay 100ms

  &-enter-from,
  &-leave-to
    transform translateX(rem(16))
    opacity 0

  &-enter-to,
  &-leave-from
    transform translateX(0)
    opacity 1

.profile__contentTitle__transition
  &-enter-active,
  &-leave-active
    transition transform 200ms, opacity 200ms

  &-enter-to,
  &-leave-from
    opacity 1
    transform translateX(0)

  &-enter-from,
  &-leave-to
    opacity 0
    transform translateX(rem(16))

.profile__sidebar__transition
  &-enter-active,
  &-leave-active
    transition transform 200ms, opacity 200ms
    transition-delay 200ms

  &-enter-to,
  &-leave-from
    opacity 1
    transform translateX(0)

  &-enter-from,
  &-leave-to
    opacity 0
    transform translateX(-100%)

.profile__mobileFallback
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  z-index 10
</style>

<script setup lang="ts">
import { OptInType } from '@/fifteen-sdk/enums';

import type { FcwProfileNavLink } from '@/components/organisms/FcwProfileNav.vue';
import type { FcwSidebarLink } from '@/components/organisms/FcwSidebar.vue';
import type {
  FcwProfileFooterCustomerServiceLink,
  FcwProfileFooterLegalLink,
} from '@/components/organisms/FcwProfileFooter.vue';

useBodyScrollLock();
const { isSmAndDown } = useFBreakpoints();

const { t } = useClientI18n();
const clientStore = useClientStore();
const { clientState, homepage } = storeToRefs(clientStore);

const route = useRoute();
const pageTitle = computed<string>(() =>
  route.meta.title ? t(route.meta.title as string) : ''
);

const authStore = useAuthStore();
const userStore = useUserStore();
const profileClientStore = useProfileStore();
const { profileClientState } = storeToRefs(profileClientStore);

const user = computed(() => userStore.user);

const userNeverOptedGeolocation = computed(() => {
  if (!user.value) {
    return false;
  }

  const geolocationOptin = user.value.opt_ins?.find(
    optIn => optIn.type === OptInType.GeolocationSharing
  );

  return !geolocationOptin?.last_validated;
});

const isSuspendedUser = computed(() => user.value?.suspended);

const hasDiscoverServices = computed(
  () => profileClientState.value.discoverServices.length > 0
);

const navbarLinks = computed<FcwProfileNavLink[]>(() => [
  ...(hasDiscoverServices.value
    ? [
        {
          title: t('nav__how_it_works'),
          location: { name: 'profile-discover' },
        },
      ]
    : []),
]);

const sidebarLinks = computed<FcwSidebarLink[]>(() => [
  {
    title: t('sidebar__profile'),
    location: { name: 'profile' },
    exact: true,
  },
  {
    title: t('sidebar__offers'),
    location: { name: 'profile-offers' },
  },
  {
    title: t('sidebar__payments'),
    location: { name: 'profile-payments' },
  },
  {
    title: t('sidebar__trips_history'),
    location: { name: 'profile-trips' },
  },
]);

const customerServiceLinks = computed<FcwProfileFooterCustomerServiceLink[]>(
  () => {
    return (
      [
        {
          icon: 'supportHelp',
          text: clientState.value.phoneNumber,
          location: `tel:${clientState.value.phoneNumber}`,
          type: 'tel',
        },
        {
          icon: 'mail',
          text: clientState.value.email,
          location: `mailto:${clientState.value.email}`,
          type: 'mailto',
        },
      ] as FcwProfileFooterCustomerServiceLink[]
    ).filter(link => link.text !== '');
  }
);

const legalLinks = computed<FcwProfileFooterLegalLink[]>(() => [
  {
    text: t('common__terms_and_conditions'),
    location: { name: 'terms-and-conditions' },
  },
  {
    text: t('common__legal_notice'),
    location: { name: 'legal-notice' },
  },
]);

async function logout(): Promise<void> {
  authStore.logout();
  await navigateTo({ name: 'profile-auth' });
}

const contentClasses = computed<VueClasses>(() => ({
  'profile__content--rootLink': !(route.meta.back as string),
}));

const contentWrapperRef = ref();
const router = useRouter();

// Scroll to the top of the nested page after each route navigation
router.afterEach(() => {
  setTimeout(() => {
    contentWrapperRef.value?.scroll({ top: 0 });
    // 200ms is the duration of the page transition, defined in transitions.styl
    // Adding 50 extra ms to handle the diff between CSS transition delay clock and JS one
  }, 250);
});
</script>
